import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { PolicyBaseComponent, PolicyBroadcasterService, LoginService, UtilityProviderService } from '@prcins/utils';
import { menus, allowed_menus, CURRENT_POLICY_CHANNEL, CURRENT_POLICY_STATUS, CURRENT_POLICY_LOB, TOKEN, USER_LOGIN, ADMIN, USER_TYPE, USER_ID, GUEST_USER_ID, BASE_URL, SHOW_RR_SUB_MENU } from '@prcins/constants';
import { BehaviorSubject } from 'rxjs';
import { RightRailService } from '@prcins/utils';
import { CURRENT_POLICY, CURRENT_POLICY_PRODUCT_CODE } from '@prcins/constants';
import { PolicyProviderService } from '../services/policy-provider.service';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'prcins-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends PolicyBaseComponent implements OnInit {
  selectedHeader = 'home';
  private menus$ = new BehaviorSubject<any>([]);
  menus = this.menus$.asObservable();
  activeMenus = {
    payments: false,
    policy: false,
    claims: false,
    documents: false,
    account: false
  };
  isWalletFlagEnabled = false;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apollo: Apollo,
    private pb: PolicyBroadcasterService,
    private loginService: LoginService,
    private utilityService: UtilityProviderService,
    private rightRailService: RightRailService,
    private cookieService: CookieService,
    private policyProvider: PolicyProviderService
  ) {
    super(pb);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkRouter(location.href);
    this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationEnd) {
        this.checkRouter(routerEvent.url);
      }
    });

    this.utilityService.unauthorizedObservable.subscribe(isUnauthorized => {
      if (isUnauthorized) {
        this.onLogout();
      }
    });
  }



  loadUiData(policyNumber) {
    const lob = sessionStorage.getItem(CURRENT_POLICY_LOB);
    const policyStatus = sessionStorage.getItem(CURRENT_POLICY_STATUS);
    const productCode = sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE);
    if (lob) {

      let allowedMenus = null;

      if (policyStatus === 'Incomplete') {
        allowedMenus = allowed_menus[lob].filter(m => m && m.startsWith('account')).map(m => menus[m]);
        allowedMenus[0].children[1].disable = true;
      } else {
        allowedMenus = allowed_menus[lob].map(m => menus[m]);
        allowedMenus.filter(m => m && m.id.startsWith('account')).forEach(m => {
          m.children[1].disable = false;
        });

        if (productCode === 'PCAT' || productCode === 'ALN_UMB') {
          allowedMenus = allowed_menus['UMB'].map(m => menus[m]);
        }
        // restore  conditions
        if (lob === 'PA') {
          allowedMenus[0].children[4].waitConditionally = true;
          allowedMenus[0].children[4].disable = true;
        }
        if (lob === 'HO' || lob === 'PA') {
          allowedMenus[3].children[0].waitConditionally = true;
          allowedMenus[3].children[0].disable = true;
        }

        if (policyStatus === 'Canceled' || policyStatus === 'Expired') {
          allowedMenus[0].children[1].waitConditionally = false;
          allowedMenus[0].children[1].disable = true;
        } else {
          allowedMenus[0].children[1].waitConditionally = false;
          allowedMenus[0].children[1].disable = false;
        }
      }
      if (sessionStorage.getItem("lob") === 'PA') {
        let showRRSubMenu = false;
        this.utilityService.inquiryLoaded$.subscribe(isInquiryLoaded => {
          if(isInquiryLoaded){

        this.policyProvider.getRRDriversDetails(policyNumber).subscribe(
          val => {
            console.log(val);

            if (val["errorCode"] == "") {
              console.info(val["data"]);
              let data = val["data"];
              //let product = data["product"]
              var channel = sessionStorage.getItem("channel");
              console.info(data["drivers"]);
              let drivers$ = data["drivers"]
              if (drivers$ != null) {

                for (var driver of drivers$) {
                  if ((channel == 'IA' &&
                    driver.activationCode != null &&
                    driver.registerDate == null) ||
                    (channel == 'IA' &&
                      driver.activationCode != null &&
                      driver.registerDate != null)
                  ) {
                    showRRSubMenu = true;
                    allowedMenus = allowed_menus[lob].map(m => menus[m]);
                    allowedMenus.filter(m => m && m.id.startsWith('policy')).forEach(m => {
                      if (m.children[5] != null) {
                        m.children[5].disable = false;
                      }
                    });
                    break;
                  }
                }

              }
            }
          }
        );

          }});
        
        allowedMenus = allowed_menus[lob].map(m => menus[m]);
        allowedMenus.filter(m => m && m.id.startsWith('policy')).forEach(m => {
          if (m.children[5] != null) {
            m.children[5].disable = true;
          }
        });
      }
      for (const menu of allowedMenus) {
        let done = false;
        if (done) {
          break;
        }
        if (menu.children && menu.children.length) {
          for (const child of menu.children) {
            if (child.label === 'Manage eDocument Preferences') {
              child.disable = this.policeNoEdoc();
              done = true;
              break;
            }
          }
        }
      }

      if(sessionStorage.getItem(GUEST_USER_ID) !== null){
        allowedMenus = allowedMenus.filter(m =>  m && m.label !== 'Account').map(m => m);
      }
      
      this.menus$.next(allowedMenus);
      this.updateMenuDisables(allowedMenus, lob);
    }
  }

  private updateMenuDisables(allowedMenus: any, lob: string) {
    const currentPolicy = sessionStorage.getItem(CURRENT_POLICY);
    const currentPolicyStatus = sessionStorage.getItem(CURRENT_POLICY_STATUS);
    if (lob === 'PA' || this.isCommercialPolicy()) {
      this.rightRailService.getQuickLinks(currentPolicy)
        .pipe(map(rightRails => {
          if (currentPolicyStatus !== 'Incomplete') {
            const isMrbActive = (rightRails && rightRails.mrbActive === false) ? false : true;
            if (this.isCommercialPolicy()) {
              //Change Pay Plan
              if (isMrbActive || rightRails.showPayPlanChange) {
                allowedMenus[0].children[3].waitConditionally = false;
                allowedMenus[0].children[3].disable = false;
                this.menus$.next(allowedMenus);
              } else {
                allowedMenus[0].children[3].waitConditionally = false;
                this.menus$.next(allowedMenus);
              }

              //ID Cards
              if (rightRails.showAutoProof) {
                allowedMenus[2].children[0].waitConditionally = false;
                allowedMenus[2].children[0].disable = false;
                this.menus$.next(allowedMenus);
              } else {
                allowedMenus[2].children[0].waitConditionally = false;
                this.menus$.next(allowedMenus);
              }


            } else {
              if (isMrbActive || rightRails.showPayPlanChange) {
                allowedMenus[0].children[4].waitConditionally = false;
                allowedMenus[0].children[4].disable = false;
                this.menus$.next(allowedMenus);
              } else {
                allowedMenus[0].children[4].waitConditionally = false;
                this.menus$.next(allowedMenus);
              }
            }


          }
        })).subscribe()
    }
    if (lob === 'HO') { // Id cards proof of insurance label disable logic
      if (currentPolicyStatus !== 'Incomplete' && (currentPolicyStatus.indexOf('Active') !== -1 || currentPolicyStatus.indexOf('Cancellation') !== -1)) {
            allowedMenus[3].children[0].waitConditionally = false;
            allowedMenus[3].children[0].disable = false;
            this.menus$.next(allowedMenus);
          } else {
            allowedMenus[3].children[0].waitConditionally = false;
            this.menus$.next(allowedMenus);
          }
    } else {
      if (currentPolicyStatus !== 'Incomplete') {
        let docsarrayIndex = 3;
        if (lob === 'CA') {
          docsarrayIndex = 2;
        }

        if (!this.isCommercialPolicy()) {
          allowedMenus[docsarrayIndex].children[0].waitConditionally = false;
          allowedMenus[docsarrayIndex].children[0].disable = false;
        }
        this.menus$.next(allowedMenus);
      }
    }
    // for manage eDocument Preferences write a similiar logic to disable it
    this.rightRailService.getQuickLinks(currentPolicy)
      .pipe(map(rightRails => {
        if (currentPolicyStatus !== 'Incomplete') {
          const isMrbActive = (rightRails && rightRails.mrbActive === false) ? false : true;

          if (isMrbActive || rightRails.showPayPlanChange) {
            if (allowedMenus[0].children[4]) {
              allowedMenus[0].children[4].waitConditionally = false;
              allowedMenus[0].children[4].disable = false;
            }
            this.menus$.next(allowedMenus);
          } else {
            if (allowedMenus[0].children[4]) {
              allowedMenus[0].children[4].waitConditionally = false;
            }
            this.menus$.next(allowedMenus);
          }

          if(!rightRails.showWallet) {
            allowedMenus = allowed_menus[lob].map(m => menus[m]);
            // Disable "Manage Payment Methods" if Wallets Feature is turned off
            allowedMenus[0].children[3].disable = true;
            this.menus$.next(allowedMenus);
        }
        }
      })).subscribe()
  }


  checkRouter(url) {
    if (url.includes('home/billing') || url.includes('home/payment')) {
      this.selectedHeader = 'payments';
    } else if (
      url.includes('home/policy') ||
      url.includes('home/documents/policy-declaration') ||
      url.includes('home/address')
    ) {
      this.selectedHeader = 'policy';
    } else if (url.includes('home/claims')) {
      this.selectedHeader = 'claims';
    } else if (url.includes('home/documents')) {
      this.selectedHeader = 'documents';
    } else if (url.includes('home/account')) {
      this.selectedHeader = 'account';
    } else {
      this.selectedHeader = 'home';
    }
  }

  onSoftLogout() {
    const preservedStorage = {
      token: sessionStorage.getItem(TOKEN),
      userLogin: sessionStorage.getItem(USER_LOGIN),
      admin: sessionStorage.getItem(ADMIN),
      userType: sessionStorage.getItem(USER_TYPE),
      userId: sessionStorage.getItem(USER_ID),
      baseURL: sessionStorage.getItem(BASE_URL)
    }
    this.utilityService.clearAppStorage();

    Object.keys(preservedStorage).forEach(key => {
      sessionStorage.setItem(key, preservedStorage[key]);
    });

    this.router.navigate(['/eservice/admin/activity'], { relativeTo: this.route });

  }

  onLogout() {
    this.utilityService.clearAppStorage();
    this.utilityService.clearCookies();
    this.loginService.userLoggedIn$.next(false);
    this.router.navigate(['../login'], { relativeTo: this.route });
  }

  private policeNoEdoc() {
    if (sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE) === 'PCAT') {
      return true;
    }
    const policyNumber = sessionStorage.getItem(CURRENT_POLICY);
    const business = ['PAC', 'PRC', 'PGC', 'CSC'];
    for (const item of business) {
      if (policyNumber.indexOf(item) !== -1) {
        return true;
      }
    }
    return false;
  }

  private isCommercialPolicy() {
    const lob = sessionStorage.getItem(CURRENT_POLICY_LOB);
    const productCd = sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE);

    return lob === "CA" && !(productCd === "PCAT" || productCd === "ALN_UMB");

  }
}
