import { BrowserModule, Meta } from '@angular/platform-browser';
import { ErrorHandler, NgModule, OnInit, NgZone } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { EserviceRoutingModule } from './eservice.routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { DesktopHeaderComponent } from './header/desktop-header/desktop-header.component';
import { MobileHeaderComponent } from './header/mobile-header/mobile-header.component';
import { PolicySelectorComponent } from './policy-selector/policy-selector.component';
import { DesktopSelectorComponent } from './policy-selector/desktop-selector/desktop-selector.component';
import { MobileSelectorComponent } from './policy-selector/mobile-selector/mobile-selector.component';
import { LandingComponent } from './landing/landing.component';
import { RightRailsComponent } from './right-rails/right-rails.component';
import { UtilsModule, JwtInterceptor, TealiumUtagService, HttpErrorInterceptor } from '@prcins/utils';
import { GraphQLModule } from './graphql.module';
import { BillingOverviewComponent } from './billing-overview/billing-overview.component';
import { BalanceDetailsComponent } from './balance-details/balance-details.component';
import { PolicyHighlightComponent } from './policy-highlight/policy-highlight.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminDeleteAccountComponent } from './admin-delete-account/admin-delete-account.component';
import { OpenClaimsComponent } from './open-claims/open-claims.component';
import { EdocsSignupComponent } from './edocs-signup/edocs-signup.component';
import { ContactEmailComponent } from './contact-email/contact-email.component';
import { LinkPolicyComponent } from './link-policy/link-policy.component';
import { CookieService } from 'ngx-cookie-service';
import { UserIdleModule } from 'angular-user-idle';
import { PremiumEntryComponent } from './premium-entry/premium-entry.component';
import { MrbNotificationComponent } from 'libs/utils/src/lib/mrb-notification/mrb-notification.component';
import { SpinnerComponent } from 'libs/utils/src/lib/spinner/spinner.component';
import { IdCardsNotificationComponent } from 'libs/utils/src/lib/id-cards-notification/id-cards-notification.component';
import { LoggerModule } from 'ngx-logger';
import * as logger from './logger/index';
import { LoggingErrorHandler } from './logger/logging-error-handler';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { EnrollAutomaticPaymentComponent } from './enroll-automatic-payment/enroll-automatic-payment.component';
import { environment } from '@prcins/environments';
import { IncompleteStatusComponent } from './incomplete-status/incomplete-status.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MobileRedirectComponent } from './mobile-redirect/mobile-redirect.component';
import { PaymentModule } from '@prcins/payment';
import {
  SessionRedirectErrorComponent
} from '../../../../libs/utils/src/lib/session-redirect-error/session-redirect-error.component';
import { EsignComponent } from "./esign/esign.component";
import { RoadRewardsBannerComponent } from './road-rewards-banner/road-rewards-banner.component';
import {
  ManageAlertPreferencesComponent
} from 'libs/account/src/lib/manage-alerts-preferences/manage-alert-preferences.component';
import { AdditionalDriversInfoComponent } from '../app/additional-drivers-info/additional-drivers-info.component';
import {
  AdditionalDriversDetailsComponent
} from '../app/additional-drivers-details/additional-drivers-details.component';
import { AdditionalDriversRspnComponent } from '../app/additional-drivers-rspn/additional-drivers-rspn.component';
import {
  QuestionnaireRenewalDriverErrorComponent
} from './questionnaire-renewal-driver-error/questionnaire-renewal-driver-error.component';
import { MobileAppLandingComponent } from "./mobile-app-landing/mobile-app-landing.component";
import { MobileAppComponent } from "./mobile-app/mobile-app.component";
import { MobileAppDownloadComponent } from "./mobile-app-download/mobile-app-download.component";
import { AdminFeatureFlagsComponent } from './admin-feature-flags/admin-feature-flags.component';
import { JpmMaintenanceComponent } from './jpm-maintenance/jpm-maintenance.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    DesktopHeaderComponent,
    MobileHeaderComponent,
    PolicySelectorComponent,
    DesktopSelectorComponent,
    MobileSelectorComponent,
    LandingComponent,
    RightRailsComponent,
    BillingOverviewComponent,
    BalanceDetailsComponent,
    PolicyHighlightComponent,
    AdminHomeComponent,
    AdminDeleteAccountComponent,
    OpenClaimsComponent,
    EdocsSignupComponent,
    ContactEmailComponent,
    LinkPolicyComponent,
    PremiumEntryComponent,
    EnrollAutomaticPaymentComponent,
    IncompleteStatusComponent,
    MobileRedirectComponent,
    EsignComponent,
    RoadRewardsBannerComponent,
    ManageAlertPreferencesComponent,
    AdditionalDriversInfoComponent,
    AdditionalDriversDetailsComponent,
    AdditionalDriversRspnComponent,
    QuestionnaireRenewalDriverErrorComponent,
    MobileAppComponent,
    MobileAppLandingComponent,
    MobileAppDownloadComponent,
    AdminFeatureFlagsComponent,
    JpmMaintenanceComponent
  ],
  imports: [
    NgSelectModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    GraphQLModule,
    UtilsModule,
    EserviceRoutingModule,
    ReactiveFormsModule,
    PaymentModule,
    LoggerModule.forRoot(logger.loggerConfig),
    DeviceDetectorModule.forRoot(),
    UserIdleModule.forRoot({idle: 30 * 60, timeout: 60, ping: 0}),
  ],
  providers: [{provide: ErrorHandler, useClass: LoggingErrorHandler}, {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  }, CookieService, DatePipe, Meta],
  bootstrap: [AppComponent],
  entryComponents: [MrbNotificationComponent, SpinnerComponent, IdCardsNotificationComponent, SessionRedirectErrorComponent],
  exports: [AdminFeatureFlagsComponent, JpmMaintenanceComponent]
})
export class AppModule {

  mobileApp = (self != top) ? true : false;

  constructor(private uTagService: TealiumUtagService, private ngZone: NgZone) {
    (window as any).ngZone = this.ngZone
    uTagService.setConfig({
      account: 'plymouth',
      profile: 'eservice',
      environment: environment.production ? 'prod' : 'qa'
    });
    if (!this.mobileApp) {
      this.loadScripts();
    }
  }

  loadScripts() {
    const dynamicScripts = environment.SCRIPTS;
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
