<!--<p>registeration-form works!</p>-->
<div class="flex flex-col item-center">
    <main class="pb-8 pt-2">      
      <prcins-danger *ngIf="registrationError">
        <span>
          {{errorMessage}}
        </span>
      </prcins-danger>
      <!-- <prcins-password-form [passwordForm]="intermediateRegisterationFormGroup" [edocPromised]="showEdocPromisedDisclaimer"></prcins-password-form> -->
      <form [formGroup]="passwordForm" autocomplete="new-password">
        <!-- {{passwordForm.value | json }}
        {{passwordForm ? passwordForm.controls? passwordForm.controls.enrollBillingTextAlert ? passwordForm.controls.enrollBillingTextAlert.value? passwordForm.controls.enrollBillingTextAlert.value : "undefinedCTrVal": "undefineAlertCTRL": "undefineCTRLs": "undefinedFrm"}}}}
        {{passwordForm ? passwordForm.controls? passwordForm.controls.billingTextAlertPhoneNumber ? passwordForm.controls.billingTextAlertPhoneNumber.value? passwordForm.controls.billingTextAlertPhoneNumber.value : "undefinedCTrVal": "undefineAlertCTRL": "undefineCTRLs": "undefinedFrm"}}}} 
        {{"testing" + alreadyRegisteredAccountPopup}} -->
        <div class="flex flex-wrap">
          <div class="w-full sm:w-2/2 md:w-3/3 lg:w-4/6 xl:w-4/6">
            <!-- Email -->
            <ng-container *ngIf="actionType === 'Create'">
              <div class="flex items-center justify-between flex-wrap mt-4 userName">
                <label class="mb-2 font-semibold" for=""></label>
                <i class="lg:w-3/5">This will be your username</i>
              </div>
              <div class="flex items-center justify-between flex-wrap mt-1">
                <label class="mb-2 font-semibold" for="">Email</label>
                <input type="text" trimInput class="w-full lg:w-3/5 es-textfield" formControlName="userName" autocomplete="new-password">
              </div>
              <div class="flex lg:justify-end">
                <prcins-error-label [control]="passwordForm.get('userName')"
                  [controlValue]="passwordForm.get('userName').value" controlLabel="passwordForm_userName"
                  [controlTouched]="passwordForm.get('userName').touched">
                </prcins-error-label>
              </div>
            </ng-container>
            
            <!-- Date of Birth -->
            <ng-container *ngIf="passwordForm.controls.dob">
              <div class="flex items-center justify-between flex-wrap mt-4">
                <label class="mb-2 font-semibold" for="">Date of Birth</label>
                <div class="flex flex-wrap w-full lg:w-3/5">
                  <input type="tel"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="10" appSecureUSDateFormatMask placeholder="MM/DD/YYYY" formControlName="dob"
                    class="w-10/12 lg:w-10/12 loginHelptext es-textfield" autocomplete="new-password">
                  <span class="add-form-help-text" style="margin-top: 5px;">
                    <prcins-help-text id="primaryUse" title="Date of Birth"
                      text="The date of birth should match the first or second named insured on a policy.">
                    </prcins-help-text>
                  </span>
                </div>
              </div>
              <div class="flex lg:justify-end">
                <prcins-error-label [control]="passwordForm.get('dob')" [controlValue]="passwordForm.get('dob').value"
                  controlLabel="userRegisterForm_displayDob" [controlTouched]="passwordForm.get('dob').touched">
                </prcins-error-label>
              </div>
            </ng-container>
            <!-- Password -->
            <ng-container>
              <div class="flex items-center justify-end flex-wrap mt-4 showPassword">
                <a *ngIf="!maskPassword; else hidePassword" class="text-es-blue-light cursor-pointer"
                  (click)="maskPassword = true">Show Password</a>
                <ng-template #hidePassword>
                  <a class="text-es-blue-light cursor-pointer" (click)="maskPassword = false">Hide Password</a>
                </ng-template>
              </div>
              <div class="flex items-center justify-between flex-wrap">
                <label *ngIf="actionType === 'Create'" class="mb-2 font-semibold" for="">Create a Password</label>
                <label *ngIf="actionType === 'Reset'" class="mb-2 font-semibold" for="">New Password</label>
                <input [type]="maskPassword ? 'text' : 'password'" trimInput class="w-full lg:w-3/5 es-textfield"
                  formControlName="password" autocomplete="new-password">
              </div>
              <div class="flex lg:justify-end">
                <prcins-error-label [control]="passwordForm.get('password')"
                  [controlValue]="passwordForm.get('password').value" controlLabel="passwordForm_password"
                  [controlTouched]="passwordForm.get('password').touched">
                </prcins-error-label>
              </div>
            </ng-container>
            <!-- Confirm Password -->
            <ng-container>
              <div class="flex items-center justify-between flex-wrap mt-4">
                <label *ngIf="actionType === 'Create'" class="mb-2 font-semibold" for="">Confirm Password</label>
                <label *ngIf="actionType === 'Reset'" class="mb-2 font-semibold" for="">Confirm New Password</label>
                <input [type]="maskPassword ? 'text' : 'password'" [prcinsNoCopyPaste]="true" prcinsNoRightClick trimInput
                  class="w-full lg:w-3/5 es-textfield" formControlName="confirmPassword" autocomplete="new-password">
              </div>
              <div class="flex lg:justify-end">
                <prcins-error-label [control]="passwordForm.get('confirmPassword')"
                  [controlValue]="passwordForm.get('confirmPassword').value" controlLabel="passwordForm_confirmPassword"
                  [controlTouched]="passwordForm.get('confirmPassword').touched">
                </prcins-error-label>
              </div>
            </ng-container>
            <div class="mt-4">
              <div>
                <label class="mb-2 font-semibold" for="">Password Criteria</label>
              </div>
              <div class="flex">
                <div class="bg-light-gray text-center m-1 p-2 w-1/3" 
                  [ngClass]="{'passwordCriteria': passwordForm.get('password').value.length >= 7}">
                  <span>
                  <label class="mb-2" for="">7 characters</label></span>
                </div>
                <div class="bg-light-gray text-center m-1 p-2 w-1/3" 
                  [ngClass]="{'passwordCriteria': hasLowerCase(passwordForm.get('password').value)}">
                  <span><label class="mb-2" for="">One lowercase letter</label></span>
                </div>
                <div class="bg-light-gray text-center m-1 p-2 w-1/3" 
                  [ngClass]="{'passwordCriteria': hasNumber(passwordForm.get('password').value)}">
                  <span><label class="mb-2" for="">1 non-alpha character</label></span>
                </div>
              </div>
            </div>
            <!-- Go Paperless with eDocuments -->
            <ng-container *ngIf="passwordForm.controls.enrollEDOC">
              <div class="flex items-center justify-between flex-wrap mt-4">
                <label class="mb-2 font-semibold w-full lg:w-2/5" for="">Go Paperless with eDocuments</label>
                <div class="add-double-checkboxes">
                  <input class="checkbox" id="activeDriver" type="radio" formControlName="enrollEDOC" value="Y" />
                  <label class="left-label" for="activeDriver">Yes</label>
                  <input class="checkbox" id="activeDriverF" type="radio" formControlName="enrollEDOC" value="N" />
                  <label class="right-label" for="activeDriverF">No</label>
                  <span class="ml-2 mt-2">
                    <prcins-help-text id="primaryUse" title="Go Paperless with eDocuments" text="eDocuments is the quickest and easiest way to get your policy and billing documents while helping to save the
                        environment! We'll send you a secure email on the same business day your documents become available. 
                        And don't worry, we'll still print and mail any of the items we are required to physically send.">
                    </prcins-help-text>
                  </span>
                </div>
              </div>
              <div class="flex lg:justify-end">
                <prcins-error-label [control]="passwordForm.get('enrollEDOC')"
                  [controlValue]="passwordForm.get('enrollEDOC').value" controlLabel="passwordForm_enrollEDOC"
                  [controlTouched]="passwordForm.get('enrollEDOC').touched">
                </prcins-error-label>
              </div>
              <div *ngIf= "!!showEdocPromisedDisclaimer" class="flex lg:justify-end">
                <label class="mb-2 font-semibold" for=""></label>
                <i class="lg:w-3/5">If you choose "No" for eDocuments, the cost of your insurance policy may increase as you will no longer be eligible for policy savings</i>
              </div>
            </ng-container>
            <!-- Sign Up for Billing Text Alerts -->
            <ng-container *ngIf="passwordForm.controls.enrollBillingTextAlert">
              <div class="flex items-center justify-between flex-wrap mt-4">
                <label class="mb-2 font-semibold w-full lg:w-2/5" for="">Sign Up for Billing Text Alerts</label>
                <div class="add-double-checkboxes">
                  <input class="checkbox" id="billingTextAlert" type="radio" formControlName="enrollBillingTextAlert" value="Y" (click)="onBillingTextAlertEnrollmentSelect('Y')" />
                  <label class="left-label" for="billingTextAlert">Yes</label>
                  <input class="checkbox" id="billingTextAlertF" type="radio" formControlName="enrollBillingTextAlert" value="N" (click)="onBillingTextAlertEnrollmentSelect('N')"/>
                  <label class="right-label" for="billingTextAlertF">No</label>
                  <span class="ml-2 mt-2">
                    <prcins-help-text id="billingTextAlertHelpText" title="Billing Text Alerts" text="Billing text alerts will send information about payment activities and billing reminders to your phone.">
                    </prcins-help-text>
                  </span>
                </div>
              </div>
              <div class="flex lg:justify-end">
                <prcins-error-label [control]="passwordForm.get('enrollBillingTextAlert')"
                  [controlValue]="passwordForm.get('enrollBillingTextAlert').value" controlLabel="passwordForm_enrollBillingTextAlert"
                  [controlTouched]="passwordForm.get('enrollBillingTextAlert').touched">
                </prcins-error-label>
              </div>
              <ng-container *ngIf="passwordForm.controls.billingTextAlertPhoneNumber">
                <div class="flex items-center justify-between flex-wrap mt-1 lg:justify-end">
                    <input type="text" prcinsPhoneMask prcinsPhoneValidation trimInput placeholder="Phone Number" class="w-full lg:w-3/5 es-textfield" formControlName="billingTextAlertPhoneNumber">
                </div>
                <div class="flex lg:justify-end">
                  <prcins-error-label [control]="passwordForm.get('billingTextAlertPhoneNumber')"
                    [controlValue]="passwordForm.get('billingTextAlertPhoneNumber').value" controlLabel="passwordForm_billingTextAlertPhoneNumber"
                    [controlTouched]="passwordForm.get('billingTextAlertPhoneNumber').touched">
                  </prcins-error-label>
                </div>
                <div class="flex lg:justify-end">
                  <label class="mb-2 font-semibold" for=""></label>
                  <i class="lg:w-3/5">Standard message and data rates may apply</i>
                </div>
              </ng-container>
            </ng-container>
           
            
          </div>
          
        </div>
      </form>
      
      <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
    </main>

  
    <div class="flex pb-8 pt-4 ">
      <label class="flex" *ngIf="showBillingAlertEnrollSection;else without_billing_alert">
        <strong class="terms">I have read and agree to the <a href="https://www.plymouthrock.com/internet-policy/consent-to-conduct" target="_blank"
          class="text-blue-600">Consent to Conduct Business Electronically</a> and following Terms and Conditions: <a href="https://www.plymouthrock.com/utility/internet-policy-popup" target="_blank"
          class="text-blue-600">Online Services</a>, <a href="https://www.plymouthrock.com/utility/terms-eDocs-popup" target="_blank"
          class="text-blue-600">eDocuments</a> and <a href="https://www.plymouthrock.com/internet-policy/mobileterms" target="_blank"
          class="text-blue-600">Text Alerts</a>.</strong>
      </label>
      <ng-template #without_billing_alert>
        <label class="flex">
          <strong class="terms">I have read and agree to the <a href="https://www.plymouthrock.com/internet-policy/consent-to-conduct" target="_blank"
            class="text-blue-600">Consent to Conduct Business Electronically</a> and following Terms and Conditions: <a href="https://www.plymouthrock.com/utility/internet-policy-popup" target="_blank"
            class="text-blue-600">Online Services</a> and <a href="https://www.plymouthrock.com/utility/terms-eDocs-popup" target="_blank"
            class="text-blue-600">eDocuments</a>.</strong>
        </label>
      </ng-template>
    </div>
  
  
  
    <div class="flex justify-center pb-2">
      <div class="w-full mt-4">
      <button class="es-btn-primary" [ngClass]="{'es-btn-primary-inactive ': !passwordForm.valid}" [disabled]="!passwordForm.valid" (click)="onSubmit()" type="button" id="eservice-register">Create Account</button>
      </div>
    </div>

    <prcins-es-popup *ngIf="alreadyRegisteredAccountPopup" headerClass="bg-es-blue text-white">
      <div header-left>
        <span>Email Address</span>
      </div>
      <div body>
        <p>We found a registered account associated with that email
          address, please select one of the following:</p>
        <div class="flex mt-4 items-center">
          <prcins-round-radio radioName="registeredAccount" radioValue="EXISTING"
            (radioChanged)="registeredAccount($event)">
          </prcins-round-radio>
          <span class="ml-2">Login to my existing account</span>
        </div>
        <div class="flex mt-4 items-center">
          <prcins-round-radio radioName="registeredAccount" radioValue="NEW_POLICY"
            (radioChanged)="registeredAccount($event)">
          </prcins-round-radio>
          <span class="ml-2">Add a new policy to my existing
            account</span>
        </div>
        <div class="add-btns">
      <button class="add-btn add-btn-primary" type="button" (click)="onContinue()">Continue</button>
          <button class="add-btn add-btn-cancel" type="button"
            (click)="alreadyRegisteredAccountPopup = false">Cancel</button>      
        </div>
      </div>
    </prcins-es-popup>
</div>
  